<template>

  <div class="sm-container">
  <div class="cart">
    <h1>Shopping Cart</h1>
    <div class="cart-table" v-if="cartList.length > 0">
      <div class="cart-item">
        <div class="cart-item-1 cart-item-1-txt">Component*Qty</div>
        <div class="cart-item-2 cart-item-1-txt">Product Number</div>
        <div class="cart-item-3"></div>
      </div>

      <div class="cart-item" v-for="item in cartList" :key="item.id">
        <div class="cart-item-1 product-info">
           <div class="product-info-img">
             <img :src="item.image">
           </div>
          <div class="product-info-txt">
            <p><b>{{ item.name }}</b></p>
            <div>
              <span>{{ item.part_no }}</span> <span>x{{ item.quantity }}</span>
            </div>
          </div>
        </div>
        <div class="cart-item-2 product-no">
          <span>No: {{ item.product }}</span>
        </div>
        <div class="cart-item-3 product-delete" @click="deleteItem(item.id)">
          <img src="@/assets/img/close.png">
        </div>
      </div>

      <div class="cart-btns">
        <div>
          <div class="continue">
            <router-link to="/">
             Continue
            </router-link>
          </div>
          <div class="continue Clear"  @click="clearCart">
            Clear
          </div>
        </div>
        <div class="confirm">
              <a href="javascript:;" @click="confirm">
                  Confirm
               </a>
        </div>
      </div>
    </div>
  </div>
</div>
  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>


</template>

<script>
import { getCart } from "@/network/main";

export default {
  name: "Cart",
  data() {
    return {
      selectedIds: {},
      cartList:[],
      insufficientStock: false,
      isPopupVisible: false,
      popupText: '',
    };
  },
  created() {
    //拿缓存数据
    const selectedIdsData = localStorage.getItem('selectedIds');
    if (selectedIdsData) {
      this.selectedIds = JSON.parse(selectedIdsData);
    }
    //获取购物车
    this.getCart();
  },
  methods: {
    getCart() {
      const ids = Object.keys(this.selectedIds);
      const idString = ids.join(',');
      console.log(idString)
      getCart(idString).then(res => {
        if (res.data.code === 200) {
          const cartData = res.data.data;
          this.cartList = cartData.map(item => {
            const quantity = this.selectedIds[item.id];
            return {
              ...item,
              quantity: quantity
            };
          });
        }
      });
    },
    //确认购物车
    confirm() {
      this.insufficientStock = false; // 重置库存不足标识
      const insufficientItems = this.cartList.filter(item => item.quantity > item.stock);
      if (insufficientItems.length > 0) {
        // 存在库存不足的商品
        this.insufficientStock = true;
        // 获取库存不足的商品名称列表
        const insufficientItemNames = insufficientItems.map(item => item.name);
        const popupText = `The following parts are Unavailable :\n${insufficientItemNames.join('\n')}`;

        this.isPopupVisible = true;
        this.popupText = popupText;
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 3000);
      } else {
        // 库存充足，跳转页面
        this.$router.push('/order');
      }
    },
    //删除
    deleteItem(id) {
      // 从缓存中删除对应的数据
      delete this.selectedIds[id];
      localStorage.setItem('selectedIds', JSON.stringify(this.selectedIds));
      // 从cartList数组中删除对应的数据
      this.cartList = this.cartList.filter(item => item.id !== id);
      // 更新selectedIdsLength
      const selectedIdsLength = Object.keys(this.selectedIds).length;
      const event = new CustomEvent('selectedIdsLengthUpdated', { detail: selectedIdsLength });
      window.dispatchEvent(event);
    },
    //清空
    clearCart() {
      // 清空购物车数据
      this.selectedIds = {};
      localStorage.removeItem('selectedIds');
      this.cartList = [];
      // 触发自定义事件
      const event = new CustomEvent('selectedIdsLengthUpdated', { detail: 0 });
      window.dispatchEvent(event);
    },
  }
};
</script>

<style scoped>
.cart{
  min-height: 800px;
}
.cart h1{
  padding: 30px 0;
}
.cart-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #CBCBCB;
  padding: 20px 0;
}
.cart-item-1{
  width: 500px;
}
.cart-item-2{
  width: 160px;
}
.cart-item-3{
  width: 30px;
}
.cart-item-1-txt{
  font-weight: bold;
}
.product-info{
  display: flex;
  align-items: center;
}
.product-info-img{
  width: 78px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D8D8D8;
  margin-right: 15px;
}

.product-info-img img{
   max-height: 78px;
   padding: 5px;
}

.product-info-txt{
  display: flex;
  flex-direction: column;
}
.product-info-txt>div{
  padding-top: 10px;
}
.product-info-txt>div span:first-child{
  background: #b7b7b7;
  padding: 5px;
  color: #fff;
  display: inline-block;
  margin-right: 20px;
  border-radius: 4px;
}
.product-info-txt>div span:last-child{
   font-weight: bold;
   font-size: 18px;
}
.product-no span{
  background: #b7b7b7;
  padding: 5px;
  color: #fff;
  display: inline;
  border-radius: 4px;
}
.product-delete{
  cursor: pointer;
}
.cart-btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.cart-btns>div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.continue{
  padding: 15px 25px;
  border: 1px solid #B4B4B4;
  border-radius: 4px;
  margin-right:30px;
  cursor: pointer;
}
.confirm{
  padding: 15px 25px;
  border-radius: 4px;
  color: #fff;
  background: var(--background);
  cursor: pointer;
}
.confirm a{
  color: #fff;
}
.no-tips h3{
  text-align: center;
  font-weight: normal;
  color: #666;
  padding-top: 100px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  white-space: pre-line;
  line-height: 2;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .cart h1{
   font-size: 24px;
  }
  .cart-item-1{
     width:50%;
  }
  .cart-item-2{
    width: 30%;
  }
  .product-info{
    flex-wrap: wrap;
  }
  .product-info-img{
     margin-right: 0;
    margin-bottom: 10px;
  }
  .continue{
    padding: 10px 20px;
    margin-right:15px;
    cursor: pointer;
  }
  .confirm{
    padding: 10px 20px;
  }
}
</style>